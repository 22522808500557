export function imageFormFile(file: File): Promise<string> {
  let promiseResolve: (value: string) => void
  if (/\.(jpe?g|png|gif|svg)$/i.test(file.name)) {
    const reader = new FileReader()

    reader.addEventListener(
      'load',
      () => promiseResolve(reader.result as string),
      false,
    )

    reader.readAsDataURL(file)
  }
  return new Promise(resolve => (promiseResolve = resolve))
}
