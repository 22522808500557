import { BButton, BCard, BCol, BFormGroup, BRow } from '@/components/bootstrap'
import Tag from '@/components/common/Tag'
import DateTimePicker from '@/components/controls/DateTimePicker'
import FormInput from '@/components/controls/form/FormInput'
import ImageUploader from '@/components/controls/ImageUploader'
import { IndentStyle } from '@/components/controls/QuillEditor'
import Select from '@/components/controls/Select'
import LoaderOverlay, { Task } from '@/components/LoaderOverlay'
import { showError } from '@/helpers/notifications'
import { uploadImage } from '@/helpers/upload'
import {
  FieldValidator,
  notEmpty,
  greaterOrEqual,
  ValidationGroup,
} from '@/helpers/validation'
import axios from '@/libs/axios'
import { blogStore, i18nStore } from '@/store'
import { ITag } from '@/store/blogStore'
import { emptyLocalizedField } from '@/store/i18nStore'
import { Vue, Component, Ref } from 'vue-property-decorator'
import Quill from 'quill'
//@ts-ignore
import { VueEditor } from 'vue2-editor'
import htmlEditButton from 'quill-html-edit-button'

import './style.scoped.scss'

Quill.register(IndentStyle, true)
Quill.register({
  'modules/htmlEditButton': htmlEditButton,
})

@Component
export default class BlogEditPage extends Vue {
  @Ref() readonly overlay!: LoaderOverlay
  @Ref() readonly vueEditor!: { quill: Quill }

  id = blogStore.article?.id || -1

  validator = new ValidationGroup()

  title = new FieldValidator({
    value: blogStore.article ? blogStore.article.title : emptyLocalizedField(),
    validators: [notEmpty()],
    localized: true,
    group: this.validator,
  })
  alias = new FieldValidator({
    value: blogStore.article ? blogStore.article.alias : '',
    validators: [notEmpty()],
    group: this.validator,
  })
  date = new FieldValidator({
    value: blogStore.article ? blogStore.article.publishDate : '',
    validators: [notEmpty()],
    group: this.validator,
  })
  fakeViews = new FieldValidator({
    value: blogStore.article ? blogStore.article.fakeViewsCount : 0,
    validators: [notEmpty(), greaterOrEqual(0)],
    group: this.validator,
  })
  selectedTag = new FieldValidator<ITag | null>({
    value: blogStore.article
      ? blogStore.tags.find(tag => tag.id === blogStore.article!.tag.id) || null
      : null,
    validators: [notEmpty(), greaterOrEqual(0)],
    group: this.validator,
  })
  previewImage = new FieldValidator<File | string | null>({
    value: blogStore.article ? blogStore.article.previewImage : null,
    validators: [notEmpty()],
    group: this.validator,
  })
  smmImage = new FieldValidator<File | string | null>({
    value: blogStore.article ? blogStore.article.smmImage : null,
    validators: [notEmpty()],
    group: this.validator,
  })
  content = new FieldValidator({
    value: blogStore.article
      ? Object.assign(emptyLocalizedField(), blogStore.article.content)
      : emptyLocalizedField(),
    validators: [notEmpty()],
    localized: true,
    group: this.validator,
  })

  editorOptions = {
    modules: {
      htmlEditButton: {},
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ color: [] }, { background: [] }],
          [{ align: [] }],
          [{ script: 'sub' }, { script: 'super' }],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ indent: '-1' }, { indent: '+1' }],
          ['link', 'image'],
          ['blockquote', 'code-block'],
          ['clean'],
        ],
      },
    },
  }

  get currentContent() {
    return this.content.value![i18nStore.selectedLanguage]!
  }
  set currentContent(value: string) {
    this.content.value![i18nStore.selectedLanguage] = value
    this.content.set(this.content.value)
  }

  async onSubmit(close = true) {
    let result = this.validator.validate()

    if (!result) {
      showError('При заполнении полей допущены ошибки')
      return
    }

    result = await this.overlay.show(this.getLoadingTasks())

    if (!result) {
      return
    }

    if (close) {
      this.$router.push('/blog/')
    } else if (blogStore.blogState === 'new') {
      blogStore.blogState = 'edit'
      history.replaceState(
        null,
        '',
        location.href.replace('blog/add', `blog/edit/${this.id}`),
      )
    }
  }

  getLoadingTasks(): Task[] {
    const tasks: Task[] = []

    if (this.previewImage.value instanceof File) {
      tasks.push({
        title: `Загрузка превью изображения ${this.previewImage.value.name}`,
        action: async () => {
          const url = await uploadImage(
            this.previewImage.value as File,
            'blog/preview',
          )
          if (url) {
            this.previewImage.set(url)
            return true
          }
          return false
        },
      })
    }

    if (this.smmImage.value instanceof File) {
      tasks.push({
        title: `Загрузка smm изображения ${this.smmImage.value.name}`,
        action: async () => {
          const url = await uploadImage(this.smmImage.value as File, 'blog/smm')
          if (url) {
            this.smmImage.set(url)
            return true
          }
          return false
        },
      })
    }

    if (blogStore.blogState === 'new') {
      tasks.push({
        title: 'Создание статьи',
        action: this.createArticle,
      })
    } else {
      tasks.push({
        title: 'Сохранение статьи',
        action: this.saveArticle,
      })
    }

    return tasks
  }

  async createArticle() {
    try {
      const response = await axios.post<{ id: number }>('v1/blog/articles', {
        title: this.title.value,
        alias: this.alias.value,
        previewUrl: this.previewImage.value,
        smmImage: this.smmImage.value,
        content: this.content.value,
        tagId: this.selectedTag.value!.id,
        fakeViewsCount: this.fakeViews.value,
      })
      this.id = response.data.id

      return true
    } catch (error) {
      console.error(error)
      showError('При создании статьи произошла ошибка')
      return false
    }
  }

  async saveArticle() {
    try {
      await axios.patch(`v1/blog/articles/${this.id}`, {
        title: this.title.value,
        alias: this.alias.value,
        previewUrl: this.previewImage.value,
        smmImage: this.smmImage.value,
        content: this.content.value,
        tagId: this.selectedTag.value!.id,
        fakeViewsCount: this.fakeViews.value,
      })
      return true
    } catch (error) {
      console.error(error)
      showError('При сохранение статьи произошла ошибка')
      return false
    }
  }

  handleImageAdded(
    file: File,
    Editor: Quill,
    cursorLocation: number,
    resetUploader: () => void,
  ) {
    this.overlay.show([
      {
        title: `Загрузка изображения ${file.name}`,
        action: async () => {
          const url = await uploadImage(file, 'blog/content')
          if (url) {
            Editor.insertEmbed(cursorLocation, 'image', url)
            resetUploader()
            return true
          }
          return false
        },
      },
    ])
  }

  protected render() {
    return (
      <LoaderOverlay ref="overlay">
        <div class="d-flex mb-1">
          <div class="flex-grow-1">
            <BButton
              onClick={() => this.onSubmit(false)}
              variant="primary"
              class="mr-1"
            >
              {blogStore.blogState === 'new'
                ? 'Создать статью'
                : 'Сохранить статью'}
            </BButton>
            <BButton onClick={() => this.onSubmit()} variant="outline-primary">
              {blogStore.blogState === 'new'
                ? 'Создать и закрыть'
                : 'Сохранить и закрыть'}
            </BButton>
          </div>
          {blogStore.blogState === 'edit' && (
            <BButton
              href={`${process.env.VUE_APP_MSC_URL}/blog/${
                blogStore.article!.alias
              }`}
              target="_blank"
              variant="flat-primary"
            >
              <feather-icon icon="ExternalLinkIcon" />
            </BButton>
          )}
        </div>
        <BCard>
          <BRow>
            <BCol sm={6}>
              <FormInput label="Заголовок" fieldValidator={this.title} />
              <FormInput label="Alias" fieldValidator={this.alias} />
              <BFormGroup
                label="Время публикации"
                state={this.date.hasError ? false : null}
                invalid-feedback={this.date.errorText}
              >
                <DateTimePicker
                  value={this.date.value}
                  onInput={this.date.set}
                  enableTime
                  dateFormat="Y-m-d H:i:S"
                  displayedFormat="l d M Y H:i"
                />
              </BFormGroup>
            </BCol>
            <BCol sm={6}>
              <BFormGroup label="Категория">
                <Select
                  value={this.selectedTag.value}
                  onInput={this.selectedTag.set}
                  options={blogStore.tags}
                  placeholder="Выберите категорию"
                  state={this.selectedTag.hasError ? false : null}
                  label="label.ru"
                  scopedSlots={{
                    option: ({ option }: { option: ITag }) => (
                      <Tag
                        label={option.label.ru!}
                        color={option.color}
                        class="m-50"
                      />
                    ),
                    selectedOption: (option: ITag) => (
                      <Tag
                        label={option.label.ru!}
                        color={option.color}
                        class="mx-50"
                      />
                    ),
                  }}
                />
                {this.selectedTag.hasError && (
                  <small class="text-danger">
                    {this.selectedTag.errorText}
                  </small>
                )}
              </BFormGroup>
              <FormInput
                label="Фейковые просмотры"
                fieldValidator={this.fakeViews}
                type="number"
                description={
                  blogStore.article
                    ? `Реальных просмотров - ${blogStore.article?.realViewsCount}`
                    : undefined
                }
              />
            </BCol>
          </BRow>

          <BRow>
            <BCol sm={6}>
              <BFormGroup
                label="Превью-изображение"
                invalid-feedback={this.previewImage.errorText}
                state={this.previewImage.hasError ? false : null}
              >
                <ImageUploader
                  value={this.previewImage.value}
                  onInput={this.previewImage.set}
                  state={this.previewImage.hasError ? false : null}
                  width="300px"
                />
              </BFormGroup>
            </BCol>
            <BCol sm={6}>
              <BFormGroup
                label="SMM-изображение"
                invalid-feedback={this.smmImage.errorText}
                state={this.smmImage.hasError ? false : null}
              >
                <ImageUploader
                  value={this.smmImage.value}
                  onInput={this.smmImage.set}
                  state={this.smmImage.hasError ? false : null}
                  width="300px"
                />
              </BFormGroup>
            </BCol>
          </BRow>

          <BFormGroup label="Контент">
            {this.content.hasError && (
              <small
                class="text-danger"
                domPropsInnerHTML={this.content.errorText!}
              />
            )}

            <VueEditor
              ref="vueEditor"
              value={this.currentContent}
              onInput={(value: string) => (this.currentContent = value)}
              editorOptions={this.editorOptions}
              useCustomImageHandler
              on={{
                'image-added': this.handleImageAdded,
              }}
              class={['vue-editor', { error: this.content.hasError }]}
            />
          </BFormGroup>
        </BCard>
      </LoaderOverlay>
    )
  }
}
