import { Component, Prop, VModel } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import './style.scoped.scss'
import { IVmodelProps } from '@/interfaces/IVmodelProps'
import Quill, { QuillOptionsStatic } from 'quill'
import parchment from 'parchment'

const { quillEditor } = require('vue-quill-editor')
const Parchment: typeof parchment = Quill.import('parchment')

type QuillFormat =
  | 'bold'
  | 'italic'
  | 'link'
  | 'strike'
  | 'underline'
  | 'indent'
  | 'list'

const levels = [1, 2, 3, 4, 5, 6, 7, 8]
const multiplier = 3

class IndentAttributor extends Parchment.Attributor.Style {
  add(node: HTMLElement, value: string) {
    return super.add(node, `${parseFloat(value) * multiplier}em`)
  }
  value(node: HTMLElement) {
    return (parseFloat(super.value(node)) / multiplier).toString()
  }
}

export const IndentStyle = new IndentAttributor('indent', 'margin-left', {
  scope: Parchment.Scope.BLOCK,
  whitelist: levels.map(value => `${value * multiplier}em`),
})

Quill.register(IndentStyle, true)

@Component({ components: { quillEditor } })
export default class QuillEditor extends tsx.Component<
  {
    placeholder?: string
    state?: boolean | null
    formats?: QuillFormat[]
    big?: boolean
  } & IVmodelProps<string>
> {
  @VModel({ type: String, required: true })
  text!: string

  @Prop({ type: String, default: '' })
  readonly placeholder!: string
  @Prop({ type: Boolean, default: null })
  readonly state!: boolean | null
  @Prop({
    type: Array,
    default: () => [
      'bold',
      'italic',
      'link',
      'strike',
      'underline',
      'indent',
      'list',
    ],
  })
  readonly formats!: QuillFormat[]
  @Prop({ type: Boolean, default: false })
  readonly big!: boolean

  get options(): QuillOptionsStatic {
    if (!this.big) {
      const formats = []
      const toolbar = []
      if (this.formats.includes('bold')) {
        formats.push('bold')
        toolbar.push('bold')
      }
      if (this.formats.includes('italic')) {
        formats.push('italic')
        toolbar.push('italic')
      }
      if (this.formats.includes('underline')) {
        formats.push('underline')
        toolbar.push('underline')
      }
      if (this.formats.includes('strike')) {
        formats.push('strike')
        toolbar.push('strike')
      }
      if (this.formats.includes('list')) {
        formats.push('list')
        toolbar.push({ list: 'ordered' })
        toolbar.push({ list: 'bullet' })
      }
      if (this.formats.includes('indent')) {
        formats.push('indent')
        toolbar.push({ indent: '-1' })
        toolbar.push({ indent: '+1' })
      }
      if (this.formats.includes('link')) {
        formats.push('link')
        toolbar.push('link')
      }

      return {
        theme: 'snow',
        placeholder: this.placeholder,
        modules: { toolbar },
        formats,
      }
    } else {
      return {
        theme: 'bubble',
        placeholder: this.placeholder,
      }
    }
  }

  protected render() {
    return (
      <quillEditor
        value={this.text}
        onInput={(value: string) => (this.text = value)}
        options={this.options}
        style={{ position: 'relative', zIndex: 10 }}
        class={[
          { error: this.state === false },
          { success: this.state === true },
        ]}
      />
    )
  }
}
