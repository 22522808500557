import { imageFormFile } from '@/helpers/image'
import { IVmodelProps } from '@/interfaces/IVmodelProps'
import { Component, Prop, Ref, VModel } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import './style.scoped.scss'

@Component
export default class ImageUploader extends tsx.Component<
  {
    accept?: string
    state?: boolean | null
    width?: string
  } & IVmodelProps<File | string | null>
> {
  @Ref()
  readonly imageInput!: HTMLInputElement

  @VModel({ type: [File, String], default: null })
  value_!: File | string | null

  @Prop({ type: String, default: '.jpg, .jpeg, .png' })
  readonly accept!: string
  @Prop({ type: Boolean, default: null })
  readonly state?: boolean | null
  @Prop({ type: String, default: '100%' })
  readonly width?: string

  preview = ''

  get displayedImage() {
    if (this.value_ instanceof File) {
      return this.preview
    } else {
      return this.value_
    }
  }

  handleFileUpload() {
    let uploadedFiles = this.imageInput.files

    if (!uploadedFiles?.length) {
      return
    }

    this.value_ = uploadedFiles[0]
    this.setImagePreview(uploadedFiles[0])
  }

  async setImagePreview(file: File) {
    this.preview = await imageFormFile(file)
  }

  protected render() {
    return (
      <div
        class={[
          'image-uploader border',
          {
            empty: !this.displayedImage,
            'border-danger': this.state === false,
            'border-success': this.state === true,
          },
        ]}
        style={{ maxWidth: this.width }}
      >
        {!!this.displayedImage && <img src={this.displayedImage} alt="" />}

        <div class="upload-btn" onClick={() => this.imageInput.click()}>
          <feather-icon icon="UploadIcon" size="30" />
        </div>

        <input
          ref="imageInput"
          type="file"
          accept={this.accept}
          hidden
          onInput={this.handleFileUpload}
        />
      </div>
    )
  }
}
